.container {
  flex: 1;
  display: flex;
  height: 100vh;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon {
  position: fixed;
  width: 135px;
  height: 130px;
  top: 40px;
  right: 40px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}

@media (max-width: 600px) {
  .icon {
    display: none;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
